import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pmid: window.pmid,
		teacher: {},
		leftmenu: 0,
		smenucount:0,
		sysopened: true,
		config: {
			logo: "",
			leftlogo: "",
			mainbg: "",
			schoolname: "",
			loginbg: "",
			favico: ""
		}
	},
	mutations: {
		setTeacher(state, value) {
			state.teacher = value
			let p = value.platform
			if (p.start_date && p.end_date) {
				let now = new Date()
				if (now >= new Date(p.start_date) && now <= new Date(p.end_date + " 23:59:00")) {
					state.sysopened = true
				} else {
					state.sysopened = false
				}
			}
		},
		setPmid(state, value) {
			state.pmid = value
		},
		showSmenu(state, value) {
			state.leftmenu = value
		},
		smenuCount(state, value) {
			state.smenucount = value
		},
		setConfig(state, value) {
			state.config = value
		},
	},
	actions: {
		setTeacher(context, value) {
			context.commit("setTeacher", value);
		},
		setPmid(context, value) {
			context.commit("setPmid", value);
		},
		showSmenu(context, value) {
			context.commit("showSmenu", value);
		},
		smenuCount(context, value) {
			context.commit("smenuCount", value);
		},
		setConfig(context, value) {
			context.commit("setConfig", value);
		},
	},
	modules: {}
})
