<template>
	<div>
		<div class="header">
			<div class="menubar" v-if="$store.state.config.leftlogo" >
				<img v-if="$store.state.config.leftlogo" :src="$store.state.config.leftlogo" alt="">
			</div>
			<div :class="['headright',$store.state.config.leftlogo?'':'nologo']">
				<div @click="goHome" :class="['moudelname',$store.state.config.leftlogo?'':'nologo']">南京市职业学校就业调查平台</div>
				<div style=" float: right; margin-right:0px ">

					<el-dropdown @command="handleCommand" trigger="click">
						<div style="width: 480px; height: 30px; font-size:18px;  position: relative; margin-top: 16px; display: flex;justify-content: flex-end;">
							<div class="niandu">年度：{{ year }}</div>
							<div style="width: 1px;height:25px;margin: 0 10px; margin-top:-5px; border-left: 1px solid #ccc"></div>
							
							<div class="nickname">
								<div style="cursor: pointer; border: 0; outline: none; color:#000; text-align:right">
									欢迎您：{{ userName }}&nbsp;&nbsp;<i style="color:#000" class="el-icon-caret-bottom"></i>
								</div>
							</div>
							<div class="headimg">
								<img :src="avatar" alt="">
							</div>
							
							<!-- c0d2ff

							f4f7ff -->

							
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="changeYear" divided>切换年份</el-dropdown-item>
							<el-dropdown-item command="updPwd" divided>修改密码</el-dropdown-item>
							<el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>



				</div>

				<div
					style="margin-right:300px ; margin-left: 150px; text-align: center; min-width: 300px; overflow: hidden;">
					<!-- <div class="bigmenu">
						<div :class="[
							'bmitem bgBlue flexCenter',
							nowbMenu == item.id ? 'bmckd' : '',
						]" v-for="(item, idx) in Menus" :key="idx" @click="goBMenu(item)">
							<i :class="item.icon"></i> {{ item.title }}
							<div class="ckdline">

							</div>
						</div>
					</div> -->
				</div>
			</div>

		</div>
		<div class="menuline"></div>



		<div :class="['leftmenu']">
			<div style="width: 100%; margin: 0 auto; height: 100%;">
				<div style="height: 51px;"></div>

				<div class="leftcollapse smenubox" style="height:100%; width: 85px; overflow: auto;">


					<div v-for="(lm, idx) in Smenus" :key="idx" :class="['menuitem', nowsMenu == lm.id ? 'menuckd' : '']">

						<div>
							<div 
								 @click="goPage(lm)">
								<div class="menuicon"><i :class="lm.icon"></i></div>
								<div class="menutitle">
									{{ lm.title }}
								</div>
								
							</div>
						</div>
						<div class="ckdline"></div>

					</div>

					<div style="height: 106px;"></div>

				</div>


			</div>
		</div>




		<el-dialog title="修改密码" class="cus_dialog" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">
			<el-form :model="pwdForm">
				<el-form-item>
					<el-input placeholder="请输入原密码" v-model="pwdForm.oldPwd" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">原密码</span></template>
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-input type="password" placeholder="请输入新密码" v-model="pwdForm.newPwd1" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">新密码</span></template>
					</el-input>
          <span style="display:inline-block;line-height: 26px !important;color: orangered">
            <strong>密码必须由数字、大小写字母、特殊字符三种以上方式组合，且长度至少6位以上</strong>
          </span>
				</el-form-item>

				<el-form-item>
					<el-input type="password" placeholder="请再次输入新密码" v-model="pwdForm.newPwd2" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">确认密码</span></template>
					</el-input>
				</el-form-item>

				<div style="height: 20px;text-align: center;padding-top: 30px;padding-bottom: 20px;">
					<el-button type="primary" style="width: 100%" @click="savePwd">更改密码</el-button>
				</div>
			</el-form>
		</el-dialog>

		<el-dialog title="切换年份" :visible.sync="isShowYearModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">

			<div>
				<el-form :model="pwdForm" inline="inline">

					<el-form-item label="年份">

						<el-select v-model="year">
							<el-option :label="y" :value="y" v-for="(y, i) in yearList" :key="i"></el-option>

						</el-select>

					</el-form-item>

				</el-form>



			</div>

			<div style="height: 20px; text-align: center; padding-top: 30px; padding-bottom: 20px;">
				<el-button type="primary" style="width: 100%;" @click="setYear">确认切换</el-button>
			</div>



		</el-dialog>


	</div>
</template>

<script>
import aes from "../../utils/aes.js";

export default {
	data() {
		return {
			leftLogo: "",
			avatar: "",
			Menus: [],
			Smenus: [],
			nowbMenu: 0,
			nowsMenu: "",
			nowsMenu2: "",
			userName: "",
			isShowModal: false,
			year: new Date().getFullYear(),
			yearList: [],
			isShowYearModal: false,
			ltmenuHide: true,
			pwdForm: {
				oldPwd: "",
				newPwd1: "",
				newPwd2: "",
			},
		};
	},
	mounted() {
		this.getRoleMenu();

		let yearList = [];
		for (let i = new Date().getFullYear(); i >= 2021; i--) {
			yearList.push(i)
		}
		this.yearList = yearList;
		if (sessionStorage.getItem("year")) {
			this.year = sessionStorage.getItem("year")
		}


	},
	methods: {
		goHome(){this.$router.push("/home")},
		getRoleMenu() {

			this.$http.post("/api/getUserMenu").then(res => {
				console.log(1)
				this.Menus = res.data
        let paths = []
				
				//this.getConfig();
				this.getTeacherInfo(() => {

					for (let item of this.Menus) {
						if (item.path) {
              paths.push(item.path)
            }

							for (let sitem of item.children) {
                if (sitem.path) {
                  paths.push(sitem.path)
                }
								
								if (sitem.path && this.$route.path.indexOf(sitem.path) >= 0) {
								
									this.nowbMenu = item.id
									this.Smenus = item.children;
									this.nowsMenu = sitem.id
									
								} else {
									for (let sitem2 of sitem.children) {
                    if (sitem2.path) {
                      paths.push(sitem2.path)
                    }
										
										if (sitem2.path && this.$route.path.indexOf(sitem2.path) >= 0) {
											this.nowsMenu = sitem.id
											this.nowbMenu = item.id
											this.nowsMenu2 = sitem2.id;
											this.Smenus = item.children;
											

										} else {


										}
									}

								}
							}
						
					}
					
					console.log("this.nowsMenu",this.nowsMenu);
					
					if(this.Smenus.length==0){
						this.Smenus = this.Menus[0].children
						if(this.$store.state.pmid==1){
							this.$router.push("/diaocha")
						}else{
							this.$router.push(this.Smenus[0].path)
						}
						//
					}
          aes.encrypt(JSON.stringify(paths)).then(res => {
            localStorage.setItem('menus', res)
          })
					
				});

			})
		},
		goBMenu(item) {
			this.Smenus = item.children;
			this.nowbMenu = item.id;
			if (this.Smenus && this.Smenus.length > 0) {

				//this.goPage(this.Smenus[0]);


				if (this.Smenus[0].children && this.Smenus[0].children.length > 0) {
					this.nowsMenu = this.Smenus[0].id;
					this.nowsMenu2 = this.Smenus[0].children[0].id;
					this.$router.push(this.Smenus[0].children[0].path);
				} else {
					this.$router.push(this.Smenus[0].path);
				}

			} else if (item.path) {
				if (this.$route.path != item.path) {
					this.$router.push(item.path);
				}


			}
		},
		handleChange() {
			for (let item of this.Smenus) {
				if (item.id == this.nowsMenu) {
					if (!item.children || item.children.length == 0) {
						this.nowsMenu = []
						this.nowsMenu2 = 0
						if (this.$route.path != item.path) {
							this.$router.push(item.path);
						}
						this.$nextTick(() => {
							this.nowsMenu = []
						})
					}
				}
			}
			console.log(this.nowsMenu)
		},
		goPage(item) {
			if (this.$route.path != item.path) {
				this.$router.push(item.path)
				this.nowsMenu = item.id
			}
		},
		setYear() {
			sessionStorage.setItem("year", this.year);
			window.location.reload();
		},
		handleCommand(e) {
			console.log(e);
			if (e == "updPwd") {
				this.isShowModal = true;
			} else if (e == "logout") {
				localStorage.clear();
				sessionStorage.clear();
				console.log(324324324, '/login')
				this.$router.push("/login");
			} else if (e == "changeYear") {
				this.isShowYearModal = true;
			}
		},
		savePwd() {
			if (!this.pwdForm.oldPwd) {
				this.$message.error("请输入原密码");
				return;
			}

			if (!this.pwdForm.newPwd1) {
				this.$message.error("请输入新密码");
				return;
			}
      let regx = /^(?=.*[a-z])(?=.*\d)(?=.*[.~!@#$%^&*)(_+}{|:?><]).{6,}$/
      if (!regx.test(this.pwdForm.newPwd1)) {
        return this.$message.error('密码必须由数字、大小写字母、特殊字符组成')
      }
			if (!this.pwdForm.newPwd2) {
				this.$message.error("请再次输入新密码");
				return;
			}
			if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
				this.$message.error("两次密码不一致");
				return;
			}
			this.$http.post("/api/reset_myself_pwd", this.pwdForm).then((ok) => {
        this.$message.success("修改成功");
        this.isShowModal = false;
			});
		},
		getConfig() {
			// this.$http.post("/api/sch_configs").then(res => {
			// 	for (let k of res.data) {

			// 		if (k.ktype == 'leftlogo') {
			// 			this.leftLogo = k.kvalue
			// 		}
			// 	}


			// })
		},
		getTeacherInfo(callback) {

			this.$http.post("/api/get_teacher_info").then(res => {
				this.avatar = res.data.avatar ? res.data.avatar : './img/temp.png'
				this.userName = res.data.username

				if (callback) {
					callback()
				}
			})
		},

	},
};
</script>
<style scoped lang="less" type="text/less">
.moudelname {
	float: left;
	margin-top:5px;
	margin-left: 20px;
	color: #fff;

	color: #000;
	font-size: 25px;font-weight: 700;
	line-height: 40px;
	padding-left: 12px;
	font-weight: bolder;
}


.leftmenu {
	width: 86px;
	height: 100%;
	position: absolute;

	transition: all .3s;
	transform-origin: left;


	.menuitem {
		padding: 10px 0;
		text-align: center;

		cursor: pointer;
		margin: 0 auto;

		font-size: 14px;
		color: #bfc1ce;
		color: #404144;
		position: relative;

		.ckdtail {
			width: 6px;
			height: 12px;
			position: absolute;
			top: 32px;
			right: 0px;
			background-image: url(/img/mleft.png);
			background-size: auto 98%;
			background-repeat: no-repeat;
		}
		.menuicon{ 
			font-size: 30px; 
			text-align: center;
			width: 50px;
			height: 50px;
			line-height: 50px;
			overflow: hidden;
			text-align: center;
			border-radius: 50%;
			margin: 0 auto;
		}
		.menutitle{ font-size: 14px; padding-top:5px}
		.ckdline{ position: absolute; right: 0; top: 0; height: 100%; width: 4px; background-color: #3D79EC; display: none;}

	}

	// .menuitem:first-child {
	//   border-top-left-radius: 4px;
	//   border-top-right-radius: 4px;
	// }

	// .menuitem:last-child {
	//   border-bottom: 1px solid #0794b3;
	//   border-bottom-left-radius: 4px;
	//   border-bottom-right-radius: 4px;
	//   border-radius: 4px;
	// }

	.menuckd {
		color: #4b77fe;
		background-color: #E0E9F8;
		.menuicon{
			width: 50px;
			height: 50px;
			line-height: 50px;
			overflow: hidden;
			text-align: center;
			border-radius: 50%;
			margin: 0 auto;
		}
		.ckdline{ display: block;}
	}


	.bmenuckd {
		color: #4b77fe;
		font-weight: bolder;
	}
}

.menuline {
	height: 2px;
	border: 2px solid #b9cbdf;
	position: absolute;
	top: 50px;
	width: 100%;
	background-color: #e1e9f9;
	display: none;
}

.hidemenu {
	transform: scale(0, 1);
}

.smenubox {
	width: 85px;
	
	margin: 0 auto;
	background-color: #fff;

	border-right: 1px solid #ddd;
}

.hidemenu {
	transform: scale(0, 1);
}

.menuitem {
	padding: 10px;
	text-align: left;
	cursor: pointer;
	margin: 0 auto;

	color: #333333;
	position: relative;

}

.menuckd {

	color: #1890ff;
	// box-shadow: 0 0 6px 2px rgba(24, 144, 255, 0.2) inset;
	border-radius: 0px;
	// border-bottom-left-radius: 20px;
	// transform: translateX(10px);
	position: relative;
}

.menuckd .ckdtail {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 0px;
	right: 0px;
	background-image: url(/img/mleft.png);
	background-size: auto 98%;

	background-repeat: no-repeat;



}

.header {
	height: 52px;
	width: 100%;
	position: absolute;
	overflow: hidden;
	top: 0;
	z-index: 1;
	background-color: #E8EDF8;
	
	.menubar {
		height: 100%;
		float: left;
		color: #000;
		text-align: center;
		line-height: 52px;

		img {
			height: 90%;
			margin-top: 3px;
			margin-left: 3px;
		}
	}
	

	.headright {
		height: 100%;
		margin-left: 200px;

		position: relative;
		text-align: center;

		.bigmenu {

			height: 40px;
			padding-top: 12px;
			color: #fff;

			.bmitem {
				height: 100%;
				padding: 3px 40px 0px 40px;
				border-radius: 4px;
				cursor: pointer;
				display: inline-block;
				vertical-align: top;
				font-weight: bolder;
				margin-right: 10px;
				font-size: 15px;

			}

			.bmckd {


				background-size: 100% 100%;
				font-size: 18px;
				color: #fff;
				transform: translateY(-2px);
				font-weight: bolder;

				.ckdline {
					height: 4px;
					background-color: #ffcb29;
					transform: translateY(5px);
					border-radius: 2.5px;
				}
			}

		}

		.nickname {
			
			
			//top: 10px;
			height: 100%;
			line-height: 100%;
			color: #fff;
			padding: 0 20px;
			border-radius: 4px;
		}

		.headimg {
			width: 40px;
			height: 40px;
		
			border: 1px solid #0794b3;
			border-radius: 50%;
			transform: translateY(-13px);
			margin-right: 10px;

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				cursor: pointer;

			}
		}
	}
	.nologo{
		margin-left: 0;
		border-left: 0;
	}
	.niandu{
		color:#000;cursor: pointer;line-height:14px;
		border: 1px solid #d6e0f6;
		background-color: #d6e0f6;
		padding: 0 10px;
		line-height: 28px;
		transform: translateY(-7px);
		border-radius: 15px;
		color: #3D79EC;
	}
}
</style>

<style>
.el-collapse {
	border: 0px solid #fff !important;
}

.el-collapse-item__header {
	border-bottom: 0px solid #fff !important;
}

.el-collapse-item__wrap {
	border-bottom: 0px solid #fff !important;
}
</style>